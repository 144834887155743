.field .field-messages{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    /*padding: 0.5vh;*/
}

.field.accordion .p-inputgroup {
    height: unset;
    width: unset;
}

.field .p-accordion {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
    background: unset
}