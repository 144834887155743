.field .p-chips-multiple-container {
    width: 100% !important;
    padding: 0;
    min-height: 100%;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.field .p-chips-token {
    margin: 0px 0px 0px 5px !important;
    /*padding: 5px !important;*/
}