.user-autocomplete-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 40px;
}

.user-autocomplete-item img{
    height: 150px;
}