.field .calendar .p-inputtext{
    width: 100% !important;
    padding: 0px 0px 0px 10px;
    height: 100%;
}

.field .p-datepicker-trigger{
    position: absolute !important;
    right: 0 !important;
    height: 100% !important;
}

.field .calendar .p-calendar {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
}