.organization-logo {
    width: 200px
}
.profile-image {
    width: 200px
}

.profile-page .p-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}