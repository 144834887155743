.field .p-inputnumber-buttons-stacked {
    display: flex !important;
}

.field .p-inputnumber-buttons-stacked input{
    border-radius: 0;
}
.field .p-inputnumber-buttons-stacked .p-inputnumber-button-group button{
    border-radius: 0;
}

.field .p-inputnumber-input {
    display: flex;
    height: 100%;
    border-radius: 0;
    width: 100%;
}
.field input.p-inputnumber-input {
    border-radius: 0;
    width: 100%;
}

.field .p-inputnumber {
    width: 100%;
}