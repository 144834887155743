.my-innovations-table {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
}
.my-innovations-table  .table-margin{
    width: 80%;
    margin-top: 10%;
}

.my-innovations-table p#innovation-title {
    display: flex;
    width: fit-content;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid #d68227;
    color: #d68227;
    padding: 10px;
    transition: 0.3s background-color, color;
}

.my-innovations-table p#innovation-title:hover {
    transition: 0.3s background-color, color;
    color: white;
    background-color: #d68227;
}

.my-innovations-table p#innovation-title-disabled {
    display: flex;
    width: fit-content;
    border-radius: 4px;
    border: 1px solid #9b9b9b;
    color: #c7c7c7;
    padding: 10px;
    transition: 0.3s background-color, color;
}